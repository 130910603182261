import React from 'react';
import { styled } from '@glitz/react';
import { theme } from '../../Theme';
import { media, pseudo } from '@glitz/core';
import ChevronIcon from '../../Shared/Icons/ChevronIcon';
import useMedia from '../../Shared/Hooks/useMedia';

type QuantityInput = {
  updateState: (value: number) => void;
  quantity: number;
};

export function QntInput({ updateState, quantity }: QuantityInput) {
  const isDesktop = useMedia(theme.mediaQuery.mediaMinLarge);

  const setQuantity = (newQuantity: number) => {
    const diff = newQuantity - quantity;
    if (diff !== 0) {
      updateState(diff);
    }
  };

  return (
    <Container>
      <Quantity
        id="input"
        min={0}
        type="number"
        defaultValue={quantity}
        placeholder={quantity.toString()}
        key={quantity}
        onBlur={e => {
          setQuantity(parseInt(e.target.value));
        }}
      />
      {isDesktop && (
        <ArrowContainer>
          <Arrow
            onClick={() => {
              updateState(1);
            }}
          >
            <StyledArrowUp />
          </Arrow>
          <Arrow
            onClick={() => {
              updateState(-1);
            }}
          >
            <StyledArrow />
          </Arrow>
        </ArrowContainer>
      )}
    </Container>
  );
}

const Container = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    height: '100%',
  }),
});

const Quantity = styled.input({
  width: '100%',
  backgroundColor: 'transparent',
  textAlign: 'center',
  height: theme.dropDownHeight,
  border: {
    y: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.quantityButtonColor,
    },
    left: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.quantityButtonColor,
    },
  },
  ':active': {
    outline: {
      width: theme.none,
    },
  },
  ':focus': {
    outline: {
      width: theme.none,
    },
  },
  ...media(theme.mediaQuery.mediaMaxLarge, {
    border: {
      right: {
        style: 'solid',
        width: theme.spacing(0.5),
        color: theme.quantityButtonColor,
      },
    },
  }),
  ...pseudo(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    width: '50%',
  }),
});

const ArrowContainer = styled.div({
  display: 'flex',
  width: '50%',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: theme.dropDownHeight,
  backgroundColor: theme.quantityButtonColor,
  border: {
    y: {
      style: 'solid',
      width: theme.spacing(0.5),
      color: theme.quantityButtonColor,
    },
    right: {
      style: 'solid',
      width: theme.tiny,
      color: theme.quantityButtonColor,
    },
  },
});

const Arrow = styled.button({
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  ':hover': { backgroundColor: theme.gray },
});

const StyledArrow = styled(ChevronIcon, {
  width: theme.spacing(3),
  height: theme.spacing(3),
  fill: theme.black,
  cursor: 'pointer',
  margin: { y: theme.none, x: 'auto' },
});

const StyledArrowUp = styled(StyledArrow, {
  transform: 'rotate(180deg)',
});
