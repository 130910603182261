import React from 'react';
import { theme } from '../Theme';
import { styled } from '@glitz/react';
import { UpdateCart } from '../Cart/Cart';
import { QntInput } from '../Shared/Input/QuantityInput';
import IconSwitch from '../Shared/Icons/IconsSwitch';
import Price from './../Shared/Price/Price';
import useEncodedCurrentUser from '../Shared/Hooks/useEncodedCurrentUser';
import {
  productPageDataLayer,
  removeFromCartDataLayer,
} from '../utils/dataLayer.lib';
import TepeLineItemModel from '../KexVariation/Models/TepeLineItemModel.interface';

type PropTypes = {
  price: string;
  code: string;
  currentQuantity: number;
  loweredPrice?: string;
  allowEdit?: boolean;
  showQuantity?: boolean;
  packageKey: string;
  sellablePackageInfo: string;
  skuQuantity: number;
  isOrderHistory?: boolean;
  product?: TepeLineItemModel;
  currency?: string;
};

function PriceInfo({
  loweredPrice,
  price,
  code,
  allowEdit = true,
  currentQuantity,
  packageKey,
  sellablePackageInfo,
  skuQuantity,
  isOrderHistory = false,
  product,
  currency,
}: PropTypes) {
  const onQuantityInputChange = (q: number) => {
    const price = product?.price! * product?.skuQuantity!;
    if (q > 0) {
      productPageDataLayer(
        true,
        price * q,
        '',
        product,
        '',
        q,
        currency,
        price
      );
    } else {
      removeFromCartDataLayer(product!, `${price * q}`, currency!, Math.abs(q));
    }
    UpdateCart(code, skuQuantity, packageKey, q, id);
  };

  const id = useEncodedCurrentUser();
  return (
    <Wrapper>
      <InfoContainer>
        {packageKey && sellablePackageInfo && (
          <PackageInfo>
            <Icon>{IconSwitch(packageKey)}</Icon>
            {isOrderHistory
              ? `${sellablePackageInfo} x ${currentQuantity}`
              : sellablePackageInfo}
          </PackageInfo>
        )}
        <Price price={price} loweredPrice={loweredPrice} isRow isSmall />
      </InfoContainer>
      {allowEdit && (
        <QuantityContainer>
          <QntInput
            updateState={q => onQuantityInputChange(q)}
            quantity={currentQuantity}
          />
        </QuantityContainer>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: theme.lineHeight.snug,
  color: theme.black,
});

const QuantityContainer = styled.div({
  maxWidth: '100px',
  height: '100%',
  margin: { left: theme.spacing(4) },
});

const PackageInfo = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.alpha,
  marginBottom: theme.spacing(1),
  textTransform: 'capitalize',
});

const Icon = styled.div({
  paddingRight: theme.spacing(2),
});

const InfoContainer = styled.div({
  textAlign: 'right',
});

export default PriceInfo;
