import React, { ReactElement } from 'react';
import PriceInfo from '../PriceInfo/PriceInfo';
import { pseudo, media } from '@glitz/core';
import TepeLineItem from '../KexVariation/Models/TepeLineItemModel.interface';
import KexLink from '../Shared/KexLink/KexLink';
import { styled, theme } from '../Theme';
import CloseIcon from '../Shared/Icons/CloseIcon';
import { UpdateCart } from '../Cart/Cart';
import ConditionalWrapper from '../Shared/Common/ConditionalWrapper';
import IconSwitch from '../Shared/Icons/IconsSwitch';
import useEncodedCurrentUser from '../Shared/Hooks/useEncodedCurrentUser';
import { removeFromCartDataLayer } from '../utils/dataLayer.lib';

type PropTypes = {
  product: TepeLineItem;
  allowEdit?: boolean;
  isOrderHistory?: boolean;
  currency?: string;
};

function ProductItem({
  product,
  allowEdit = false,
  isOrderHistory = false,
  currency,
}: PropTypes) {
  const id = useEncodedCurrentUser();
  return (
    <Item>
      <Info>
        <Details>
          <ConditionalWrapper
            condition={allowEdit}
            wrapper={(children: ReactElement) => (
              <StyledKexLink href={product.productUrl}>
                {children}
              </StyledKexLink>
            )}
          >
            <>
              <Title>{product.name}</Title>
              <Code> - {product.articleNumber ?? product.code}</Code>
            </>
          </ConditionalWrapper>
          <DetailText>
            <Icon>{IconSwitch(product.variantPackageKey)}</Icon>
            {product.variantPackageInfo}
          </DetailText>
        </Details>
        <RightContainer>
          <PriceInfo
            price={product.totalPrice}
            code={product.code}
            currentQuantity={product.sellablePackageQuantityInCart}
            allowEdit={allowEdit}
            packageKey={product.sellablePackageKey}
            sellablePackageInfo={product.sellablePackageInfo}
            skuQuantity={product.skuQuantity}
            isOrderHistory={isOrderHistory}
            loweredPrice={product.discountedPrice}
            product={product}
            currency={currency}
          />
          {allowEdit && (
            <Actions>
              <ActionButton
                onClick={() => {
                  removeFromCartDataLayer(product, '', currency);
                  UpdateCart(
                    product.code,
                    product.skuQuantity * product.sellablePackageQuantityInCart,
                    product.sellablePackageKey,
                    0,
                    id
                  );
                }}
              >
                <Trash />
              </ActionButton>
            </Actions>
          )}
        </RightContainer>
      </Info>
    </Item>
  );
}

const StyledKexLink = styled(KexLink, {
  margin: { bottom: theme.spacing(2) },
});

const RightContainer = styled.div({ display: 'flex' });

const Item = styled.div({
  display: 'flex',
  position: 'relative',
  padding: { y: theme.spacing(2) },
  ...pseudo(':not(:last-of-type)::after', {
    content: '""',
    position: 'absolute',
    width: '100%',
    margin: {
      x: 'auto',
      y: 0,
    },
    display: 'block',
    backgroundColor: theme.gray,
    height: theme.tiny,
    bottom: 0,
    opacity: 0.5,
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    flexDirection: 'row',
  }),
  flexDirection: 'column',
});

const Actions = styled.div({
  display: 'flex',
});

const Info = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'start',
  ...media(theme.mediaQuery.mediaMinMedium, {
    justifyContent: 'space-between',
  }),
});

const Title = styled.span({
  font: { size: theme.gamma, weight: theme.fontWeight.bold },
  margin: { bottom: theme.spacing(2) },
  letterSpacing: theme.letterSpacing.nearWide,
  color: theme.black,
});

const Code = styled.span({
  font: { size: theme.alpha, weight: theme.fontWeight.normal },
  margin: { bottom: theme.spacing(2) },
  letterSpacing: theme.letterSpacing.nearWide,
  color: theme.black,
});

const Details = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const DetailText = styled.span({
  font: {
    size: theme.alpha,
  },
  color: theme.black,
  margin: { bottom: theme.spacing(1) },
  ...pseudo(':last-of-type', {
    margin: { bottom: theme.spacing(2) },
  }),
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
});

const Icon = styled.div({
  paddingRight: theme.spacing(2),
});

const ActionButton = styled.div({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  margin: { xy: theme.none },
});

const Trash = styled(CloseIcon, {
  width: theme.spacing(3.5),
  height: theme.spacing(4),
  margin: { xy: theme.spacing(4) },
});

export default ProductItem;
