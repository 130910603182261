import React from 'react';
import { styled, theme } from '../Theme';
import ProductItem from '../ProductItem/ProductItem';
import ProductItemMobile from '../ProductItem/ProductItemMobile';
import TepeLineItem from '../KexVariation/Models/TepeLineItemModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import useMedia from '../Shared/Hooks/useMedia';

type PropTypes = {
  products: TepeLineItem[];
  isLoading: boolean;
  allowEdit?: boolean;
  edit?: boolean;
  setEdit?: (edit: boolean) => void;
  isOrderHistory?: boolean;
  currency?: string;
};

function ProductList({
  products,
  isLoading,
  allowEdit = false,
  edit = false,
  isOrderHistory = false,
  setEdit,
  currency,
}: PropTypes) {
  const {
    translations: { 'common/loading': loading },
  } = useAppSettingsData();
  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  return isLoading ? (
    <>{loading}</>
  ) : (
    <Wrapper>
      {products &&
        products.map(product =>
          isMobile ? (
            <ProductItemMobile
              key={product.code + product.sellablePackageKey}
              product={product}
              edit={edit}
              currency={currency}
              {...(setEdit && { setEdit: setEdit })}
            />
          ) : (
            <ProductItem
              key={product.code + product.sellablePackageKey}
              product={product}
              allowEdit={allowEdit}
              currency={currency}
              isOrderHistory={isOrderHistory}
            />
          )
        )}
    </Wrapper>
  );
}

const Wrapper = styled.div({
  marginTop: theme.spacing(2),
});

export default ProductList;
