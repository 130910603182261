import React from 'react';
import TepeLineItem from '../KexVariation/Models/TepeLineItemModel.interface';
import { pseudo, media } from '@glitz/core';
import { StyleOrStyleArray } from '@glitz/type';
import { styled, theme } from '../Theme';
import { UpdateCart } from '../Cart/Cart';
import CloseIcon from '../Shared/Icons/CloseIcon';
import { QntInput } from '../Shared/Input/QuantityInput';
import IconSwitch from '../Shared/Icons/IconsSwitch';
import Price from './../Shared/Price/Price';
import useGetCurrentUser from '../Shared/Hooks/useEncodedCurrentUser';
import {
  productPageDataLayer,
  removeFromCartDataLayer,
} from '../utils/dataLayer.lib';

type PropTypes = {
  product: TepeLineItem;
  edit: boolean;
  setEdit?: (edit: boolean) => void;
  currency?: string;
};

function ProductItemMobile({ product, edit, currency }: PropTypes) {
  const email = useGetCurrentUser();

  const onQuantityInputChange = (q: number) => {
    const price = product?.price! * product?.skuQuantity!;
    if (q > 0) {
      productPageDataLayer(
        true,
        price * q,
        '',
        product,
        '',
        q,
        currency,
        price
      );
    } else {
      removeFromCartDataLayer(product, `${price * q}`, currency!, Math.abs(q));
    }
    UpdateCart(
      product.code,
      product.skuQuantity,
      product.sellablePackageKey,
      q,
      email
    );
  };

  return (
    <Item>
      <Row>
        <Column>
          <TitleWrapper>
            <ColumnTitle>{product.name}</ColumnTitle>
            <Code> - {product.code}</Code>
          </TitleWrapper>

          <PackageInfo>
            {IconSwitch(product.sellablePackageKey)}
            <SellablePackageInfo>
              {product.sellablePackageInfo}
              {' X' + product.sellablePackageQuantityInCart}
            </SellablePackageInfo>
          </PackageInfo>
        </Column>
      </Row>
      <Row>
        <Column css={justifyLeftStyle}>
          {IconSwitch(product.variantPackageKey)}
          <VariantPackageInfo>{product.variantPackageInfo}</VariantPackageInfo>
        </Column>
        <Column css={justifyRightStyle}>
          <Price price={product.totalPrice} isRow isSmall />
        </Column>
      </Row>
      <Row css={justifyRightStyle}>
        {edit && (
          <Container>
            <Column>
              <QuantityWrapper>
                <QntInput
                  updateState={q => onQuantityInputChange(q)}
                  quantity={product.sellablePackageQuantityInCart}
                />
              </QuantityWrapper>
              <ActionButton
                onClick={() => {
                  removeFromCartDataLayer(product, '', currency);
                  UpdateCart(
                    product.code,
                    product.skuQuantity * product.sellablePackageQuantityInCart,
                    product.sellablePackageKey,
                    0,
                    email
                  );
                }}
                css={justifyRightStyle}
              >
                <Trash />
              </ActionButton>
            </Column>
          </Container>
        )}
      </Row>
    </Item>
  );
}

const justifyRightStyle: StyleOrStyleArray = {
  display: 'flex',
  justifyContent: 'flex-end',
};
const justifyLeftStyle: StyleOrStyleArray = {
  display: 'flex',
  justifyContent: 'flex-start',
};

const Container = styled.div({ marginTop: theme.spacing(5) });

const SellablePackageInfo = styled.div({
  marginLeft: theme.spacing(1),
});

const QuantityWrapper = styled.div({
  height: theme.arrowButtonWidth,
  width: theme.arrowButtonWidth,
});

const VariantPackageInfo = styled.div({
  marginLeft: theme.spacing(1),
  fontSize: theme.alpha,
  display: 'flex',
  alignItems: 'center',
});

const TitleWrapper = styled.div({ display: 'flex' });

const Code = styled.span({
  font: { size: theme.tau, weight: theme.fontWeight.normal },
  margin: { bottom: theme.spacing(3) },
  letterSpacing: theme.letterSpacing.nearWide,
  color: theme.black,
});

const Item = styled.div({
  position: 'relative',
  padding: { y: theme.spacing(4) },
  ...pseudo(':not(:last-of-type)::after', {
    content: '""',
    position: 'absolute',
    width: '100%',
    margin: {
      x: 'auto',
      y: theme.none,
    },
    display: 'block',
    backgroundColor: theme.gray,
    height: theme.tiny,
    bottom: theme.none,
    opacity: 0.5,
  }),
  flexDirection: 'row',
  color: theme.black,
  lineHeight: theme.lineHeight.normal,
});

const PackageInfo = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.tau,
  marginBottom: theme.spacing(3),
  textTransform: 'capitalize',
});

const Column = styled.div({
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const Row = styled.div({
  display: 'flex',
});

const ColumnTitle = styled.div({
  font: { size: theme.tau, weight: theme.fontWeight.bold },
  margin: { bottom: theme.spacing(3) },
  letterSpacing: theme.letterSpacing.nearWide,
  color: theme.black,
});

const ActionButton = styled.div({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { right: theme.spacing(4) },
  }),
  margin: { xy: theme.none },
});

const Trash = styled(CloseIcon, {
  width: theme.spacing(3.5),
  height: theme.spacing(4),
  margin: { xy: theme.spacing(4) },
  marginRight: theme.none,
});

export default ProductItemMobile;
